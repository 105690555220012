<template>
  <div class="contain">
    <div
      class="left"
      :style="{ backgroundImage: 'url(' + loginImg + ')' }"
    ></div>
    <div class="right">
      <div class="main">
        <img v-if="logo" class="login-logo" :src="logo" alt="LOGO" />
        <h1 v-else class="logo-str">LOGO</h1>
        <!-- 账号登录 -->
        <div v-show="isAccountLogin">
          <a-form layout="vertical" :model="loginParams">
            <a-form-item label="账号">
              <a-input
                v-model:value.trim="loginParams.account"
                placeholder="请输入您的手机号/邮箱/账号"
                size="large"
                @pressEnter="fnLogin"
                @blur="checkPass"
              >
                <template #addonBefore>
                  <UserOutlined style="font-size: 24px; color: #9e9e9e" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="密码">
              <a-input-password
                v-model:value.trim="loginParams.password"
                placeholder="请输入您的密码"
                size="large"
                @pressEnter="fnLogin"
              >
                <template #addonBefore>
                  <LockOutlined style="font-size: 24px; color: #9e9e9e" />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item label="验证码" v-if="imgCaptchaShow">
              <a-input
                v-model:value.trim="loginParams.captcha"
                placeholder="请输入验证码"
                size="large"
                @pressEnter="fnLogin"
                :maxlength="10"
              >
                <template #addonBefore>
                  <SafetyCertificateOutlined
                    style="font-size: 24px; color: #9e9e9e"
                  />
                </template>
              </a-input>
              <img
                class="captcha-img"
                :src="captchaObj.img"
                alt="code"
                @click="getImgCaptcha"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                size="large"
                @click="fnLogin"
                :loading="loading"
                >登录</a-button
              >
            </a-form-item>
          </a-form>
          <div class="footer" style="display: block">
            <template v-if="useRegister == 1">
              <router-link to="/register">注册账号</router-link>
              &nbsp;&nbsp;&nbsp;&nbsp;<span
                style="color: #a6b0bf; vertical-align: 2px"
                >|</span
              >&nbsp;&nbsp;&nbsp;
            </template>
            <router-link to="/forgot">忘记密码</router-link>
            <!-- &nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #a6b0bf; vertical-align: 2px">|</span>&nbsp;&nbsp;&nbsp;
            <span class="scanCode" @click="isAccountLogin = false">
              <QrcodeOutlined /> 扫码登录
            </span> -->
          </div>
        </div>
        <!-- 扫码登录 -->
        <div v-show="!isAccountLogin">
          <div class="login-type">
            <span
              style="margin-right: 53px"
              @click="$message.info('请扫描下方二维码进行登录')"
            >
              <QrcodeOutlined /> 扫码登录
            </span>
            <span @click="isAccountLogin = true">
              <LoginOutlined /> 账号登录
            </span>
          </div>
          <img src="" class="qrCode" />
          <p>扫码后点击“确认授权”，即可完成账号绑定及登录</p>
          <p>支持扫码登录的APP:</p>
          <div class="login-more">
            <span class="wx" @click="$message.info('暂未开放该功能')">
              <WechatOutlined style="font-size: 30px; color: #01bc0d" />
              微信
            </span>
            <span class="dd" @click="$message.info('暂未开放该功能')">
              <DingtalkCircleFilled style="font-size: 30px; color: #0089ff" />
              钉钉
            </span>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span v-if="studyCopyright" style="padding: 0 5px">{{
          studyCopyright
        }}</span>
        <a
          :href="`https://beian.miit.gov.cn${
            newWindow == 1 ? '?ddtab=true' : ''
          }`"
          :target="newWindow == 1 ? '_blank' : '_self'"
          v-if="icpNo"
        >
          {{ icpNo }}
        </a>
        <a
          v-if="ncNo"
          :target="newWindow == 1 ? '_blank' : '_self'"
          :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${ncNo}${
            newWindow == 1 ? '&ddtab=true' : ''
          }`"
        >
          公网安备 {{ ncNo }}号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { login, accountCheck } from "@/api/user";
import { getCaptcha } from "@/api/other";
import { appendJs, clearStorage } from "@/utils/tools.js";
import { currentHost } from "@/utils/request.js";
import { successCallback } from "@/utils/login.js";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      redirect: decodeURIComponent(route.query.redirect || "/"),
      loginParams: {
        captcha: "",
        account:
          process.env.NODE_ENV === "production" ||
          process.env.NODE_ENV === "low"
            ? ""
            : "admin",
        password:
          process.env.NODE_ENV === "production" ||
          process.env.NODE_ENV === "low"
            ? ""
            : "admin123@",
      },
      isAccountLogin: true,
      loading: false,
      imgCaptchaShow: false,
      captchaObj: {
        id: "",
        img: "",
      },
      logo: "",
      useRegister: 2,
      studyCopyright: "",
      icpNo: "",
      ncNo: "",
      loginImg: "",
      defalitLoginImg: require("@/assets/image/login1920.png"),
    });

    onMounted(() => {
      watch(
        () => store.getters.platformConfig,
        (val) => {
          state.useRegister = val.useRegister;
          state.logo = val.logo1;
          state.loginImg = val.loginImg || state.defalitLoginImg;
          state.studyCopyright = val.studyCopyright;
          state.icpNo = val.icpNo;
          state.ncNo = val.ncNo;
          appendJs("Track", val.studyTrack);
        },
        { immediate: true, deep: true }
      );
    });

    clearStorage();

    const checkPass = () => {
      accountCheck({
        domain: currentHost,
        account: proxy.$getRsaCode(state.loginParams.account),
      }).then((res) => {
        if (
          res.data &&
          res.data.logins >= 3 &&
          res.data.logins <= res.data.max
        ) {
          getImgCaptcha();
          state.imgCaptchaShow = true;
        }
      });
    };

    const fnLogin = async () => {
      if (state.loginParams.account == "") {
        return proxy.$message.error("请输入账号！");
      } else if (state.loginParams.password == "") {
        return proxy.$message.error("请输入密码！");
      } else if (state.imgCaptchaShow && state.loginParams.captcha == "") {
        return proxy.$message.error("请输入验证码！");
      }

      state.loading = true;
      let formData = {
        domain: currentHost,
        platform: 1,
        account: proxy.$getRsaCode(state.loginParams.account),
        password: proxy.$getRsaCode(state.loginParams.password),
      };
      if (state.imgCaptchaShow) {
        formData.captcha = state.loginParams.captcha;
        formData.captchaKey = state.captchaObj.id;
      }
      let res = await login(formData);
      state.loading = false;
      if (res.ret === 0) {
        successCallback(res, state);
        store.dispatch("setPlatform", "web");
      } else {
        if (
          res.data &&
          res.data.logins >= 3 &&
          res.data.logins < res.data.max
        ) {
          getImgCaptcha();
          state.imgCaptchaShow = true;
        }
      }
    };

    const getImgCaptcha = () => {
      getCaptcha().then((res) => {
        state.captchaObj = res.data;
      });
    };
    getImgCaptcha();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      fnLogin,
      getImgCaptcha,
      checkPass,
    };
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100vw;
  height: 100vh;
  min-height: 800px;
  overflow-y: hidden;
  .left {
    width: 40%;
    height: 100%;
    float: left;
    position: relative;
    min-height: 600px;
    // background-image: url(../assets/image/login1920.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .right {
    width: 60%;
    height: 100%;
    float: right;
    min-height: 600px;
    position: relative;
  }
  .main {
    height: 576px;
    width: 576px;
    margin: 15% auto;
    .login-logo {
      max-width: 368px;
      height: 67px;
      margin-bottom: 60px;
    }
    .logo-str {
      width: 368px;
      height: 67px;
      margin-bottom: 60px;
      line-height: 67px;
      font-size: 36px;
      letter-spacing: 4px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .footer {
    padding: 10px 2px 0;
    margin-bottom: 40px;
    font-size: 16px;
    color: #a6b0bf;
    a {
      font-size: 16px;
      color: #a6b0bf;
    }
    .scanCode {
      cursor: pointer;
    }
  }
  .bottom {
    width: 100%;
    position: absolute;
    bottom: 30px;
    text-align: center;
    font-size: 12px;
    color: #b0bbc3;
    ::v-deep(a) {
      color: #b0bbc3 !important;
    }
  }
  .ant-form-item {
    margin-bottom: 40px;
    ::v-deep(.ant-form-item-label > label) {
      font-size: 16px;
      color: #202020;
    }
    ::v-deep(.ant-form-item-label) {
      padding: 0 0 12px;
    }
    ::v-deep(.ant-input-group-addon) {
      padding: 0 22px;
      position: absolute;
      left: 1px;
      top: 1px;
      z-index: 99;
      height: 97%;
      line-height: 66px;
      border: none;
      background: none;
    }
    ::v-deep(.ant-input-lg) {
      padding: 20.5px 11px 20.5px 66px;
      border-radius: 4px;
    }
    ::v-deep(.ant-input-affix-wrapper-lg) {
      padding: 0 11px 0 1px;
      border-radius: 4px;
    }
    ::v-deep(.ant-btn-lg) {
      width: 300px;
      height: 68px;
      padding: 6.4px 75px;
      border-radius: 4px;
      background: @color-theme;
      border-color: @color-theme;
      font-size: 20px;
      box-shadow: 0px 7px 10px 0px rgba(59, 109, 205, 0.2);
    }
  }
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 75px;
  }
  .login-type {
    font-size: 20px;
    color: #202020;
    span {
      cursor: pointer;
    }
  }
  .qrCode {
    width: 246px;
    height: 246px;
    margin: 29px 0 60px;
    box-shadow: 22px 22px 10px #f4f7fc;
  }
  p {
    font-size: 16px;
    color: #a6b0bf;
    margin-bottom: 22px;
  }
  .login-more {
    font-size: 16px;
    color: #202020;
    .wx,
    .dd {
      margin-right: 51px;
      cursor: pointer;
    }
    .anticon {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.captcha-img {
  position: absolute;
  right: 0;
  top: 1px;
  width: 220px;
  height: 66px;
  cursor: pointer;
  z-index: 100;
}
</style>
